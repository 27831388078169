import { EnvironmentUrls } from 'Roblox';
import UrlConfig from '../../../../../../Roblox.CoreScripts.WebApp/Roblox.CoreScripts.WebApp/js/core/http/interfaces/UrlConfig';

const URL_NOT_FOUND = 'URL_NOT_FOUND';
const apiGatewayUrl = EnvironmentUrls.apiGatewayUrl ?? URL_NOT_FOUND;

const rostileServiceUrl = `${apiGatewayUrl}/rostile`;

export enum RostileError {
  UNKNOWN = 0,
  INTERNAL_ERROR = 1,
  INVALID_REQUEST = 2,
  INVALID_SESSION = 3
}

/**
 * Request Type: `POST`.
 */
export const VERIFY_PUZZLE_CONFIG: UrlConfig = {
  withCredentials: true,
  url: `${rostileServiceUrl}/v1/verify`,
  timeout: 10000
};

export type Solution = {
  buttonClicked?: boolean;
  completionTime?: number;
  clickTime?: number;
  clickX?: number;
  clickY?: number;
  clickType?: number;
  mouseMovements?: Array<{ x: number; y: number; timestamp: number }>;
};

export type VerifyPuzzleReturnType = {
  redemptionToken: string;
};
